import React, { useEffect, useState, useContext } from 'react';
import { FirebaseContext, useUser } from '../Firebase';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import 'draft-js/dist/Draft.css';

import { makeStyles } from '@material-ui/core/styles';
import RichTextEditor from 'react-rte';

const useStyles = makeStyles((theme) => ({
  editField: {
    margin: theme.spacing(1),
  },
}));

export function EditNoteFetcher(props) {
  let [noteId, setNoteId] = useState(props.noteId);
  let [note, setNote] = useState(null);
  let firebase = useContext(FirebaseContext);
  let user = useUser();

  useEffect(() => {
    if (noteId) {
      firebase.getNoteThen(noteId, setNote);
    } else {
      firebase.database().ref(`users/${user.uid}/notes`).push({}).then(ref => setNoteId(ref.key))
    }
  }, [firebase, noteId, user]);

  return note && <EditNote note={note} id={noteId} autoFocus={true}/>;
}

export function EditNote(props) {
  const classes = useStyles();
  let firebase = useContext(FirebaseContext);

  let [title, setTitle] = useState((props.note && props.note.title) || "");
  let [source, setSource] = useState((props.note && props.note.source) || "");
  let content = (props.note && props.note.content) || "";
  let detail = (props.note && props.note.detail) || "";
  const [richContent, setRichText] = useState(getRichContent);
  let [richDetail, setRichDetail] = useState(getRichDetail);

  function getRichContent() {
    if (props.note.richContent) {
      return RichTextEditor.createValueFromString(props.note.richContent, 'html');
    } else if (props.note.content) {
      return RichTextEditor.createValueFromString(props.note.content, 'html');
    }
    return RichTextEditor.createEmptyValue();
  }

  function getRichDetail() {
    if (props.note.richDetail) {
      return RichTextEditor.createValueFromString(props.note.richDetail, 'html');
    } else if (props.note.detail) {
      return RichTextEditor.createValueFromString(props.note.detail, 'html');
    }
    return RichTextEditor.createEmptyValue();
  }

  function writeNote() {
    const created = new Date();
    const modified = new Date();
    firebase.writeNote(props.id, {content, richContent, detail, richDetail, source, title, created, modified});
  }

  return (
    <div style={{ marginTop: 5 + 'em' }}>
      <Paper elevation={3} >
        <TextField fullWidth className={classes.editField} id="title" value={title} label="Title" onChange={event => setTitle(event.target.value)} /><br />
        <TextField fullWidth className={classes.editField} id="source" value={source} label="Source" onChange={event => setSource(event.target.value)} /><br />
        <RichTextEditor value={richContent} onChange={setRichText} autoFocus={props.autoFocus}/>
        <RichTextEditor value={richDetail} onChange={setRichDetail} toolbarHidden={true} />
        <Button variant="contained" color="primary" onClick={writeNote}>
          Save
        </Button>
      </Paper>

    </div>
  )
}

export default EditNoteFetcher