import AppBar from '@material-ui/core/AppBar';
// import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { BrowserRouter, Link, Route } from "react-router-dom";
import EditListPage from '../EditListPage';
import EditNotePage from '../EditNotePage/edit-note-page';
import HomePage from '../HomePage/home-page';
import LandingPage from '../LandingPage';
import NotesListPage from '../NotesListPage';
import SearchPage from '../SearchPage';
import SourcesListPage from '../SourcesListPage';
import TagsListPage from '../TagsListPage';
import './App.css';
import SignInToggle from '../SignInToggle';
import { useUser } from '../Firebase';
import { alpha } from '@material-ui/core/styles'

const App = () => (
  <BrowserRouter>
  <ResponsiveDrawer/>
  </BrowserRouter>
);


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
    grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerItems = [
    {label: "Notes", 
     loc: "/notes"},
     {label: "Sources", 
     loc: "/sources"},
     {label: "Editor", 
     loc: "/editor"},
     {label: "Tags", 
     loc: "/tags"},
    ]

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Link to="/edit">

      <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          className={classes.margin}
        >
          New Note
        </Fab></Link>
      <Divider />
      <List>
        {drawerItems.map((item, index) => (
          <ListItem button key={item.label}>
            <Link to={item.loc}><ListItemText primary={item.label} /></Link>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Noter
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <SignInToggle/>

          </div>
          <div className={classes.sectionMobile}>
            <SignInToggle/>
            </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="nav drawer">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
      <hr />
      <NoterContents />
      <div className={classes.toolbar} />
      </main>
    </div>
  );
}

function NoterContents() {
  const user = useUser();

  if (!user) {
    return "You are not logged in!";
  }

  return (
    <div style={{marginTop: '4em'}}>
      <Route exact path="/landing" component={LandingPage} />
      <Route path="/home" component={HomePage} />
      <Route path="/notes/:mode?/:modeParam?" component={NotesListPage} />
      <Route path="/search" component={SearchPage} />
      <Route path="/edit/:id?" component={EditNotePage} />
      <Route path="/editor/:mode?/:modeParam?" component={EditListPage} />
      <Route path="/sources" component={SourcesListPage} />
      <Route path="/tags" component={TagsListPage} />
    </div>);
}

export default App;
