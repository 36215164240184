
import React from 'react';

  
const SearchPage = () => (
  <div>
    search
  </div>
);

export default SearchPage;
