// import Note from '../../model/note'
import hash from "string-hash"
import React, { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../Firebase';

function SourcesListPage(props) {

  let [sourcesList, setSourcesList] = useState({});
  let firebase = useContext(FirebaseContext)

  useEffect(() => {
    return firebase.getSourceNamesThen(setSourcesList);
  }, [firebase]);

  return (Object.entries(sourcesList).map(value => (
    <SourceLink key={value[0]} source={value[1]}  />
  )))
}

function SourceLink(props) {
  return (
    <div>
      <a href={"/notes/source/" + hash(props.source.source)}>{props.source.source}</a>
    </div>
  )
}

export default SourcesListPage;
