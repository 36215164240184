import React, { useContext, useEffect, useRef, useState, useReducer, useCallback } from 'react';
import { FirebaseContext, useNoteIds } from '../Firebase';
import {EditNote} from "../EditNote/edit-note"
import { Button, TextField } from '@material-ui/core';

function EditListPage(props) {
  let noteIds = useNoteIds(props.match.params.mode, props.match.params.modeParam);

  return noteIds.length > 0 && <EditList noteIds={noteIds}/>
}

function EditList(props) {
  let [noteIndex, setNoteIndex] = useState(0);
  // let [notes, setNotes] = useState({});
  const [notes, noteDispatch] = useReducer(reducer, {});

  function reducer(state, action) {
    switch (action.type) {
      case 'set':
        state[action.id] = action.note;
        return {...state};
      default:
        throw new Error();
    }
  }
  
  let [tags, setTags] = useState("");
  let firebase = useContext(FirebaseContext);

  const onDown = event => {
    if (event.shiftKey && event.key === "ArrowRight" && noteIndex < props.noteIds.length) {
      setNoteIndex(noteIndex + 1);
    } else if (event.shiftKey && event.key === "ArrowLeft" && noteIndex > 0) {
      setNoteIndex(noteIndex - 1);
    } else if (event.shiftKey && event.key === "Enter" && noteIndex > 0) {
      appendTags();
      setNoteIndex(noteIndex+1);
    }
    return true;
  }

  const updateNotes = useCallback(() => {
    for (let i = noteIndex; i < noteIndex + 10 && i < props.noteIds.length; i++) {
      let currentId = props.noteIds[i];
      firebase.getNoteThen(currentId, (note) => {
        if (!note.richContent) {
          note.richContent = note.content;
        }
        noteDispatch({type: 'set', id: currentId, note: note});
      });
    }
  }, [firebase, props.noteIds, noteIndex]);

  useEffect(() => {
    updateNotes();
  }, [noteIndex, updateNotes])

  function appendTags() {
    let currentNote = notes[props.noteIds[noteIndex]];
    currentNote.richContent += " " + tags;
    noteDispatch({type: 'set', id: props.noteIds[noteIndex], note: currentNote});
    firebase.setNoteContent(props.noteIds[noteIndex], currentNote.richContent);
  }

  function slurp() {
    let currentNote = notes[props.noteIds[noteIndex]];
    let priorNote = notes[props.noteIds[noteIndex - 1]];
    currentNote.title = priorNote.title;
    currentNote.source = priorNote.source;
    noteDispatch({type: 'set', id: props.noteIds[noteIndex], note: currentNote});
    firebase.setNote(props.noteIds[noteIndex], currentNote);
  }

  useEventListener('keydown', onDown);

  if (props.noteIds.length === 0 || !notes[props.noteIds[noteIndex]]) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <EditNote id={props.noteIds[noteIndex]} note={notes[props.noteIds[noteIndex]]} key={noteIndex} />
      <TextField fullWidth id="tags" value={tags} label="Tags" onChange={event => setTags(event.target.value)} autoFocus={true}/><br />
      <Button onClick={slurp}>Slurp</Button>
    </React.Fragment>
    )
}

// https://usehooks.com/useEventListener/
// Hook
function useEventListener(eventName, handler, element = window){
  // Create a ref that stores handler
  const savedHandler = useRef();
  
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On 
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      
      // Create event listener that calls handler function stored in ref
      const eventListener = event => savedHandler.current(event);
      
      // Add event listener
      element.addEventListener(eventName, eventListener);
      
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
};

export default EditListPage;
