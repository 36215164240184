import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, set, onValue, remove } from 'firebase/database';
import 'firebase/compat/database';
import { useContext, useEffect, useState, } from 'react';
import FirebaseContext from './context';
import Note from '../../model/note';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { query, limitToLast } from '@firebase/database';
var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

// const devConfig = {
//   apiKey: process.env.REACT_APP_DEV_API_KEY,
//   authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
//   projectId: process.env.REACT_APP_DEV_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
// };

// const config =
//   process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

const app = initializeApp(firebaseConfig);

// Initialize Firebase
class Firebase {
  constructor() {
    this.auth = getAuth(app);
    this.db = getDatabase(app);
  }

  addAuthListener(listener) {
    return this.auth.onAuthStateChanged(listener);
  }

  doSignInWithGoogle() {
    let provider = new GoogleAuthProvider();
    return signInWithPopup(this.auth, provider);
  }

  doSignOut = () => this.auth.signOut();

  getCurrentUser() {
    return this.auth.currentUser;
  }

  database() {
    return this.db;
  }

  setNote(id, note) {
    let user = this.getCurrentUser();
    if (user === null) {
      console.log("bail")
      return;
    }
    console.log(`users/${user.uid}/notes/${id}`)
    set(ref(this.db, `users/${user.uid}/notes/${id}`), note);
  }

  setNoteContent(id, richContent) {
    let user = this.getCurrentUser();
    if (user === null) {
      console.log("bail")
      return;
    }
    console.log(`users/${user.uid}/notes/${id}`)
    set(ref(this.db, `users/${user.uid}/notes/${id}/richContent`), richContent);
  }

  getNotesThen(callback) {
    let user = this.getCurrentUser();
    if (user === null) {
      console.log("bailing")
      callback([])
      return;
    }
    let notesQuery = query(ref(this.db, `users/${user.uid}/notes`), limitToLast(200));
    onValue(notesQuery, snap => {
        var ret = snap.val();
        Object.keys(ret).map(key => {
          ret[key].id = key;
          return null;
        })
        callback(ret || [])
      });
  }

  getSourceNamesThen(callback) {
    let user = this.getCurrentUser();
    onValue(ref(this.db, `users/${user.uid}/sourcenames`), snap => callback(snap.val() || []));
  }

  getTagNamesThen(callback) {
    let user = this.getCurrentUser();
    onValue(ref(this.db, `users/${user.uid}/tags`), snap => callback(snap.val() || []));
  }

  getTagNotesThen(tagId, callback) {
    let user = this.getCurrentUser();
    let tagNotesRef = ref(this.db, `users/${user.uid}/tags/` + tagId)
    onValue(tagNotesRef, snap => {
      let val = snap.val();
      console.log(val)
      var promises = Object.keys(val).map((noteId => {
        return get(ref(this.db, `users/${user.uid}/notes/` + noteId), snap => {
          var ret = snap.val() || {};
          ret.id = noteId;
          return ret;
        })
      }))
      return Promise.all(promises).then(callback);
    });
  }

  getDuplicateNotesThen(callback) {
    let user = this.getCurrentUser();
    onValue(ref(this.db, `users/${user.uid}/duplicates/`), snap => {
      if (!snap.val()) { return; }
      var promises = Object.keys(snap.val()).map((noteId => {
        return get(ref(this.db, `users/${user.uid}/notes/` + noteId), snap => {
          var ret = snap.val() || {}
          ret.id = noteId;
          return ret
        })}));
      return Promise.all(promises).then(values => {
        console.log(values)
        values = values.filter(item => item != null && item.content);
        values.sort((a, b) => {
          if (!a || !b) {
            return 0;
          } else if (a.content > b.content) {
            return -1
          } else {
            return 1
          }
        });
        callback(values);
      });
    });
  }

  // TODO: Merge with getTagNotesThen
  getSourceNotesThen(tagId, callback) {
    let user = this.getCurrentUser();
    onValue(ref(this.db, `users/${user.uid}/sources/` + tagId), snap => {
      // TODO: callback with an error?  
      console.log("1")
      if (!snap.val()) { return; }
      console.log(snap.val())

      var promises = Object.keys(snap.val()).map((noteId => {
        console.log(noteId)
        return get(ref(this.db, `users/${user.uid}/notes/` + noteId), snap => {
          var ret = snap.val() || {}
          ret.id = noteId;
          return ret
        })}));
      Promise.all(promises).then(values => {
        callback(values);
      });
    });
  }

    writeNote(id, {content, richContent, detail, richDetail, source, title, created, modified}) {
      let richContentHtml = richContent.toString('html');
      let richDetailHtml = richDetail.toString('html');
      const note = new Note({content, richContent: richContentHtml, detail, richDetail: richDetailHtml, source, title, created, modified});
      console.log(note)
      this.setNote(id, note);
    }


  getNoteThen(id, callback) {
    let user = this.getCurrentUser();
    let notesRef = ref(this.db, `users/${user.uid}/notes/` + id);
    onValue(notesRef, (snap) => callback(snap.val() || []));
  }

  deleteNote(id) {
    let user = this.getCurrentUser();
    let notesRef = ref(this.db, `users/${user.uid}/notes/` + id);
    remove(notesRef);
  }

}

export function useUser() {
  let [user, setUser] = useState("");

  useEffect(() => {
    getAuth(app).onAuthStateChanged(setUser)
  }, [setUser]);
  return user;
}

export function useNoteIds(type, id) {
  let user = useUser();
  let firebase = useContext(FirebaseContext);
  let [noteIds, setNoteIds] = useState([]);

  function setKeys(snap) {
    setNoteIds(Object.keys(snap.val()));
  }

  useEffect(() => {
    if (!user) {return;}

    if (type === "tag") {
      onValue(ref(firebase.database(), `users/${user.uid}/tags/` + id), setKeys);
    } else if (type === "source") {
      onValue(ref(firebase.database(), `users/${user.uid}/sources/` + id), setKeys);
    } else {
      onValue(ref(firebase.database(), `users/${user.uid}/noteids`), setKeys);
    }
  }, [firebase, user, type, id])
  return noteIds;
}

export default Firebase;