import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import hash from "string-hash";
import { FirebaseContext } from '../Firebase';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 500,
    padding: '12px',
    margin: '12px',
    display: 'inline-block'
  },
});

function NotesListPage(props) {

  let [notesList, setNotesList] = useState({});
  let firebase = useContext(FirebaseContext);

  function edit() {
    props.history.push(`/editor/${props.match.params.mode}/${props.match.params.modeParam}`);
  }

  useEffect(() => {
    function loadNotes() {
      console.log('hello')
      if (props.match.params.mode === "tag") {
        return firebase.getTagNotesThen(
          props.match.params.modeParam,
          notes => { setNotesList(notes) }
        );
      } else if (props.match.params.mode === "source") {
        return firebase.getSourceNotesThen(
          props.match.params.modeParam,
          notes => { setNotesList(notes) }
        );
      } else if (props.match.params.mode === "duplicates") {
        return firebase.getDuplicateNotesThen(
          notes => { setNotesList(notes) }
        );
      } else {
        return firebase.getNotesThen(notes => { setNotesList(notes); });
      }
    };

    firebase.addAuthListener(loadNotes);
  }, [firebase, props.match.params]);

  let noteLinks = Object.entries(notesList).map(value => (
    <NoteCard note={value[1]} firebase={firebase} history={props.history}
      key={value[1].id} />));


  return (
    <React.Fragment>
      <div><Button onClick={edit}>Edit</Button></div>
      {noteLinks}
    </React.Fragment>
  )
}

function NoteCard(props) {
  const classes = useStyles();
  let firebase = useContext(FirebaseContext);
  const content = getRichContent();
  const detail = getRichDetail();

  function deleteNote(event) {
    firebase.deleteNote(props.note.id);
    console.log(props.note.id)
    event.stopPropagation();
  }

  function open() {
    props.history.push("/edit/" + props.note.id);
  }

  let source = props.note.source || "";
  if (source && source.substring(0, 4) === "http") {
    source = <a href={source}>{source.split("/")[2]}</a>
  }

  let detailEditor = detail && <RichTextEditor value={detail} readOnly={true} toolbarHidden={true}/>;
  return (
    <Card className={classes.root} variant="outlined" onClick={open}>
      <RichTextEditor value={content} readOnly={true} toolbarHidden={true}/>
      {detailEditor}
      {props.note.title}<br />
      {source} <br />
      <a href={"/notes/source/" + hash(source)}>other notes</a><br />
      <Button onClick={deleteNote}>Delete</Button>
    </Card>
  )

  // TODO consolidate EditNote and NoteCard
  function getRichContent() {
    if (props.note.richContent) {
      return RichTextEditor.createValueFromString(props.note.richContent, 'html');
    } else if (props.note.content) {
      return RichTextEditor.createValueFromString(props.note.content, 'html');
    }
    return RichTextEditor.createEmptyValue();
  }

  function getRichDetail() {
    if (props.note.richDetail) {
      return RichTextEditor.createValueFromString(props.note.richDetail, 'html');
    } else if (props.note.detail) {
      return RichTextEditor.createValueFromString(props.note.detail, 'html');
    }
    return null;
  }

}

export default NotesListPage;
