import React, { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../Firebase';

function TagsListPage() {
  let [tagsList, setTagsList] = useState({});
  let firebase = useContext(FirebaseContext)

  useEffect(() => {
    return firebase.getTagNamesThen(setTagsList);
  }, [firebase]);

  return (Object.keys(tagsList).map(value => (
    <TagLink key={value} tag={value} />
  )))
}

function TagLink(props) {
  return (
    <div>
      <a href={"/notes/tag/" + props.tag}>{props.tag}</a>
    </div>
  )
}

export default TagsListPage;
