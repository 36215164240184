
import React from 'react';
  
const LandingPage = () => (
  <div>
    landing
  </div>
);

export default LandingPage;
