class Note {
  constructor({content, richContent, detail, richDetail, source, title, created, modified}) {
    this.content = content;
    this.richContent = richContent;
    this.detail = detail;
    this.richDetail = richDetail;
    // this.tags = tags;
    this.source = source;
    this.title = title;
    this.created = JSON.stringify(created);
    this.modified = JSON.stringify(modified);
  }
}
export default Note