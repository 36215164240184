import React from 'react';

  
const HomePage = () => (
  <div>
    home
  </div>
);

export default HomePage;
