import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../Firebase';


function SignInToggle(props) {
  let [user, setUser] = useState("");
  let firebase = useContext(FirebaseContext);
  firebase.addAuthListener(setUser);
  return user ? <Button onClick={firebase.doSignOut}>Sign Out</Button> : <SignIn />;
}

function SignIn(props) {
  let firebase = useContext(FirebaseContext);
  function onSignInClick(event) {
    firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        console.log(socialAuthUser)
        // this.props.history.push({pathname: ROUTES.HOME, search: window.location.search});
      })
    event.preventDefault();
  };

  return (
    <IconButton
      edge="end"
      aria-label="account of current user"
      aria-haspopup="true"
      color="inherit"
      onClick={onSignInClick}
    >
      <AccountCircle />
    </IconButton>
  );
}



export default SignInToggle;
